import { NavLink } from "react-router-dom";
import { useNavigation } from "../../reducer/hooks";

import "./NavBar.scss";

function NavBar() {
  const { showManageAreas } = useNavigation();

  return (
    <nav>
      <div>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/Collection">Collection</NavLink>
        <NavLink to="/Checklist">Checklist</NavLink>
        <NavLink to="/Feats">Feats</NavLink>
        <NavLink to="/About">About</NavLink>
        <NavLink to="/Settings">Settings</NavLink>
        {showManageAreas ? <NavLink to="/Manage">Manage</NavLink> : <></>}
      </div>
    </nav>
  );
}

export default NavBar;
