import { useState } from "react";
import CollectionSaveFile from "../../../types/CollectionSaveFile";
import Feedback from "../../Feedback";
import { useCollection, useDispatch } from "../../../reducer/hooks";
import { convertDataIntoSaveFile } from "../../../helpers/collectionHelper";

function SettingsImportExportTab() {
  const collection = useCollection();
  const dispatch = useDispatch();

  const [importFeedback, setImportFeedback] = useState<[string, boolean]>([
    "",
    false,
  ]);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteDone, setDeleteDone] = useState<boolean>(false);

  const importButtonClick = () => {
    setImportFeedback(["", false]);

    const fileUploadElement = document.getElementById(
      "importFileUpload",
    ) as HTMLInputElement | null;
    if (!!!fileUploadElement) {
      setImportFeedback(["Could not find file upload.", false]);
      return;
    }

    if (!!!fileUploadElement.files?.length) {
      setImportFeedback(["Please upload a JSON file.", false]);
      return;
    }

    const fileInUpload = fileUploadElement.files[0];

    if (!fileInUpload.name.endsWith(".json")) {
      setImportFeedback(["Please upload a JSON file.", false]);
      return;
    }

    // Read in file contents via FileReader
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const fileContent = fileReader.result;

      if (!!!fileContent || typeof fileContent !== "string") {
        setImportFeedback([
          "Please upload a valid collection JSON file.",
          false,
        ]);
        return;
      }

      const saveFile = JSON.parse(fileContent) as CollectionSaveFile;

      if (!!!saveFile) {
        setImportFeedback([
          "Please upload a valid collection JSON file.",
          false,
        ]);
        return;
      }

      // Pass file to collection method, and interpret response.
      // Validate
      let feedback = [true, ""];
      if (!!!saveFile.version) feedback = [false, "Missing version"];
      if (!!!saveFile.collection) feedback = [false, "Missing collection"];
      if (!!!saveFile.wishlist) feedback = [false, "Missing wishlist"];

      const [success, reason] = feedback;
      if (!success) {
        setImportFeedback([
          "The collection you have uploaded cannot be imported: " + reason,
          false,
        ]);
        return;
      }

      // Accept file and update collection
      dispatch({
        type: "IMPORT_COLLECTION",
        saveFile: saveFile,
      });

      setImportFeedback(["Collection imported successfully!", true]);
    };
    fileReader.onerror = (e) => {
      console.error(fileReader.error);
      setImportFeedback(["Error occured while importing.", false]);
    };
    fileReader.readAsText(fileInUpload);
  };

  const exportButtonClick = () => {
    const fileToExport = convertDataIntoSaveFile(collection);

    // Set file name and type
    const filename = "feh-collection.json";
    const contentType = "application/json;charset=utf-8;";

    // Handle downloading
    const a = document.createElement("a");
    a.download = filename;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(fileToExport, null, "\t"));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <h3>Import / Export</h3>
      <h4>Export your data</h4>
      <p>
        You can download your collection as a JSON file, if you want to store
        them locally. You can then import your collection back into the site
        using the same file.
      </p>
      <button onClick={exportButtonClick}>Export to JSON file</button>
      <hr />

      <h4>Import data</h4>
      <p>
        If you have downloaded your collection as a JSON file using the export
        functionality, you can import the file here. This will overwrite the
        collection on the site to match the collection in the file.
      </p>
      {!!importFeedback[0] && (
        <>
          <Feedback
            feedback={{
              message: importFeedback[0],
              type: importFeedback[1] ? "Positive" : "Negative",
            }}
          />
        </>
      )}
      <input type="file" id="importFileUpload" accept=".json" />
      <button onClick={importButtonClick}>Import from JSON file</button>
      <hr />

      <h4>Delete data</h4>
      <p>
        Deleting your site data will remove all the heroes in your collection,
        and reset your data to the site's defaults.
      </p>
      {!confirmDelete && (
        <>
          <input
            type="button"
            value="Delete data"
            onClick={() => {
              setConfirmDelete(true);
            }}
          />
        </>
      )}
      {confirmDelete && !deleteDone && (
        <>
          <p>Are you sure?</p>
          <input
            type="button"
            value="Yes"
            onClick={() => {
              dispatch({
                type: "RESET_COLLECTION",
              });

              setDeleteDone(true);
            }}
          />
          <input
            type="button"
            value="No"
            onClick={() => {
              setConfirmDelete(false);
            }}
          />
        </>
      )}
      {deleteDone && (
        <>
          <p>Data deleted.</p>
        </>
      )}
    </>
  );
}

export default SettingsImportExportTab;
