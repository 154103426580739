import HeroInfo from "../../types/HeroInfo";
import HeroTableRow from "../HeroTableRow";

type HeroTableProps = {
  heroes: HeroInfo[];
};

function HeroTable(props: HeroTableProps) {
  return (
    <table>
      <thead>
        <tr>
          <th>Hero</th>
          <th className="mediumAndUp--table-cell">Weapon Type</th>
          <th className="mediumAndUp--table-cell">Move Type</th>
          <th className="mediumAndUp--table-cell">First available</th>
          <th className="smallAndUp--table-cell">Source</th>
          <th colSpan={2}></th>
        </tr>
      </thead>
      <tbody>
        {props.heroes.map((hero) => {
          return (
            <HeroTableRow key={`hero-table-row-${hero.shortId}`} hero={hero} />
          );
        })}
      </tbody>
    </table>
  );
}

export default HeroTable;
