import { Action } from "../actions";

export type NavigationState = {
  loading: boolean;
  broken: boolean;
  showManageAreas: boolean;
};

export const navigationInitialState: NavigationState = {
  loading: false,
  broken: false,
  showManageAreas: false,
};

export const navigationReducer = (
  state: NavigationState,
  action: Action,
): NavigationState => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.loading };
    case "SET_BROKEN":
      return { ...state, broken: action.broken };
    case "SET_MANAGE_AREAS_STATE":
      return { ...state, showManageAreas: action.show };
    default:
      return state;
  }
};
