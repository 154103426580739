import { useDispatch, useFilters, useSiteData } from "../../reducer/hooks";
import TextDataField from "../TextDataField";
import SelectDataField from "../SelectDataField";
import { SelectDataFieldItem } from "../SelectDataField/SelectDataField";
import HeroFilterableFields from "../../types/HeroFilterableFields";

type HeroFilterPanelProps = {
  bookOptions: number[];
};

function HeroFilterPanel(props: HeroFilterPanelProps) {
  const siteData = useSiteData();
  const filters = useFilters();
  const dispatch = useDispatch();

  const mapItems = (
    items: { id: number | string; name?: string; title?: string }[],
    defaultItem: SelectDataFieldItem = { text: "Any", value: "" },
  ): SelectDataFieldItem[] => {
    return [
      defaultItem,
      ...items.map((item) => {
        return {
          text: item.name ?? item.title ?? "",
          value: item.id,
        };
      }),
    ];
  };

  const filterChange = (
    value: string,
    fieldName: keyof HeroFilterableFields,
  ) => {
    const selectedValue = parseInt(value ?? "0");
    const newFilters = {
      ...filters,
      [fieldName]: !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
    };
    dispatch({ type: "SET_FILTERS", filters: newFilters });
  };

  return (
    <>
      <TextDataField
        label="Name"
        value={filters.name}
        onChange={(value) => {
          dispatch({
            type: "SET_FILTERS",
            filters: { ...filters, name: value },
          });
        }}
      />
      <SelectDataField
        label="Colour"
        value={filters.heroColors[0] ?? ""}
        onChange={(value) => filterChange(value, "heroColors")}
        options={mapItems(siteData.heroColors)}
      />
      <SelectDataField
        label="Weapon"
        value={filters.heroWeapons[0] ?? ""}
        onChange={(value) => filterChange(value, "heroWeapons")}
        options={mapItems(siteData.heroWeapons)}
      />
      <SelectDataField
        label="Move Type"
        value={filters.heroMoveTypes[0] ?? ""}
        onChange={(value) => filterChange(value, "heroMoveTypes")}
        options={mapItems(siteData.heroMoveTypes)}
      />
      <SelectDataField
        label="Ability"
        value={filters.heroAbilities[0] ?? ""}
        onChange={(value) => filterChange(value, "heroAbilities")}
        options={mapItems(siteData.heroAbilities)}
      />
      <SelectDataField
        label="Source"
        value={filters.heroSources[0] ?? ""}
        onChange={(value) => filterChange(value, "heroSources")}
        options={mapItems(siteData.heroSources)}
      />
      <SelectDataField
        label="Summoning Pool"
        value={filters.heroSummoningPools[0] ?? ""}
        onChange={(value) => filterChange(value, "heroSummoningPools")}
        options={mapItems(siteData.heroSummoningPools)}
      />
      <SelectDataField
        label="Theme"
        value={filters.heroThemes[0] ?? ""}
        onChange={(value) => filterChange(value, "heroThemes")}
        options={mapItems(siteData.heroThemes)}
      />
      <SelectDataField
        label="Game"
        value={filters.games[0] ?? ""}
        onChange={(value) => filterChange(value, "games")}
        options={mapItems(siteData.games)}
      />
      <SelectDataField
        label="Book"
        value={filters.books[0] ?? ""}
        onChange={(value) => filterChange(value, "books")}
        options={[
          {
            text: "Any",
            value: "",
          },
          ...(!!props.bookOptions &&
            props.bookOptions.map((bookNumber) => {
              return {
                text: bookNumber.toString(),
                value: bookNumber,
              };
            })),
        ]}
      />
      <SelectDataField
        label="Obtained?"
        value={filters.obtained?.toString() ?? ""}
        onChange={(value) => {
          const selectedValue = value ?? "";
          const booleanValue = !!!selectedValue
            ? undefined
            : selectedValue === "false"
            ? false
            : true;
          const newFilters = { ...filters, obtained: booleanValue };
          dispatch({ type: "SET_FILTERS", filters: newFilters });
        }}
        options={[
          {
            text: "Either",
            value: "",
          },
          {
            text: "Yes",
            value: "true",
          },
          {
            text: "No",
            value: "false",
          },
        ]}
      />

      <SelectDataField
        label="Wishlisted?"
        value={filters.wishlisted?.toString() ?? ""}
        onChange={(value) => {
          const selectedValue = value ?? "";
          const booleanValue = !!!selectedValue
            ? undefined
            : selectedValue === "false"
            ? false
            : true;
          const newFilters = { ...filters, wishlisted: booleanValue };
          dispatch({ type: "SET_FILTERS", filters: newFilters });
        }}
        options={[
          {
            text: "Either",
            value: "",
          },
          {
            text: "Yes",
            value: "true",
          },
          {
            text: "No",
            value: "false",
          },
        ]}
      />
    </>
  );
}

export default HeroFilterPanel;
