export default Object.freeze({
    ShadowDragonAndTheBladeOfLight: 1,
    Gaiden: 2,
    MysteryOfTheEmblem: 3,
    GenealogyOfTheHolyWar: 4,
    Thracia776: 5,
    BindingBlade: 6,
    BlazingSword: 7,
    SacredStones: 8,
    PathOfRadiance: 9,
    RadiantDawn: 10,
    ShadowDragon: 11,
    NewMysteryOfTheEmblem: 12,
    Awakening: 13,
    Fates: 14,
    ShadowsOfValentia: 15,
    Heroes: 16,
    ThreeHouses: 17,
    Engage: 18,

    Cipher: 101,
    TokyoMirageSessions: 102,
    Warriors: 103,
    WarriorsThreeHopes: 104,
    BSFireEmblem: 105
});