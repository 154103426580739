const getDayOfWeek = (date: Date) => {
  const dayNumber = date.getDay();

  switch (dayNumber) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
  }
};

const getDateWithSuffix = (date: Date) => {
  const dateNumber = date.getDate();

  switch (dateNumber) {
    case 1:
    case 21:
    case 3:
      return `${dateNumber}st`;
    case 2:
    case 22:
      return `${dateNumber}nd`;
    case 3:
    case 23:
      return `${dateNumber}rd`;
    default:
      return `${dateNumber}th`;
  }
};

const getMonthName = (date: Date) => {
  const monthNumber = date.getMonth();

  switch (monthNumber) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
  }
};

const getDate = (date: string | Date | undefined | null): Date | null => {
  if (date === null || date === undefined) {
    return null;
  }

  if (typeof date === "string") {
    return new Date(date);
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  ) as Date;
};

const formatDateAsString = (
  date: string | Date | null,
  format: "ISO" | "fullDate" | "fullDateWithDay" | "shortDate" = "ISO",
) => {
  const handledDate = getDate(date);

  if (handledDate === null) {
    return "";
  }

  if (format === "shortDate") {
    return `${handledDate.getDate().toString().padStart(2, "0")}/${(
      handledDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${handledDate
      .getFullYear()
      .toString()
      .padStart(4, "0")}`;
  }
  if (format === "fullDate" || format === "fullDateWithDay") {
    let finalString = "";

    if (format === "fullDateWithDay") {
      finalString += `${getDayOfWeek(handledDate)}, `;
    }
    finalString += `${getDateWithSuffix(handledDate)} `;
    finalString += `${getMonthName(handledDate)} `;
    finalString += handledDate.getFullYear();

    return finalString;
  }

  return handledDate.toISOString();
};

export { getDate, formatDateAsString };
