import ManageTile from "../../components/ManageTile";
import KeyListener from "../../components/KeyListener";
import PageTitle from "../../components/PageTitle";
import { useAdmin, useDispatch } from "../../reducer/hooks";
import { useEffect } from "react";

function ManageIndex() {
  const { adminPassword } = useAdmin();
  const dispatch = useDispatch();

  const promptPassword = () => {
    const password = prompt("Enter admin password.");

    dispatch({
      type: "SET_ADMIN_PASSWORD",
      password: password ?? "",
    });
    dispatch({
      type: "SET_MANAGE_AREAS_STATE",
      show: true,
    });
  };

  useEffect(() => {
    if (window.innerWidth < 600 && !adminPassword) {
      promptPassword();
    }
  }, []);

  return (
    <>
      <KeyListener chain={["#", "#", "#", "#"]} action={promptPassword} />

      <PageTitle>Manage Site</PageTitle>

      {!!adminPassword ? (
        <>
          <h3>Internal</h3>
          <ManageTile text="Heroes" path="/Manage/Heroes/" />
          <ManageTile text="Banners" path="/Manage/Banners/" />
          <h3>External</h3>
          <ManageTile text="Import" path="/Manage/Import/" />
        </>
      ) : (
        <p>Please enter password.</p>
      )}
    </>
  );
}

export default ManageIndex;
