import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiHero from "../../types/api/ApiHero";
import FeedbackType from "../../types/Feedback";
import HeroGameLink from "../../types/HeroGameLink";
import HeroInfo from "../../types/HeroInfo";
import Feedback from "../../components/Feedback";
import HeroColorIds from "../../lookups/HeroColorIds";
import HeroWeaponIds from "../../lookups/HeroWeaponIds";
import Globals from "../../lookups/Globals";
import HeroSourceIds from "../../lookups/HeroSourceIds";
import HeroSummoningPoolIds from "../../lookups/HeroSummoningPoolIds";
import HeroAbilityIds from "../../lookups/HeroAbilityIds";
import ScrapeRequest from "../../types/ScrapeRequest";
import { getDate } from "../../helpers/dateHelper";
import { useAdmin, useDispatch, useSiteData } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";
import DataField from "../../components/DataField";
import HeroImagePane from "../../components/HeroImagePane";
import TextDataField from "../../components/TextDataField";
import DatepickerDataField from "../../components/DatepickerDataField";
import DataTerm from "../../components/DataTerm";
import Games from "../../lookups/Games";

const fetchHeroData = async (id: string): Promise<HeroInfo> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/Hero/${id}`);
  if (response.ok) {
    const hero = (await response.json()) as HeroInfo;
    if (hero.firstAvailable) return hero;
  }
  return {} as HeroInfo;
};

function ManageHero() {
  const params = useParams();
  let id = params.id ?? "";
  let isNew = !!!id;

  const siteData = useSiteData();
  const { adminPassword } = useAdmin();
  const dispatch = useDispatch();

  const [hero, setHero] = useState<HeroInfo>({
    heroColor: {},
    heroWeapon: {},
    heroMoveType: {},
    heroSource: {},
    heroAbility: {},
    heroSummoningPool: {},
    images: {
      full: {},
      thumbnail: {},
    },
    heroGames: [],
  } as unknown as HeroInfo);

  const [feedback, setFeedback] = useState<FeedbackType | null>(null);

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      loading: loading,
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);

      fetchHeroData(id).then((data) => {
        setHero(data);
        setLoading(false);
      });
    } else {
      const currentDate = new Date();
      const currentBookNumber =
        currentDate.getFullYear() -
        Globals.FEH_APP_LAUNCH_DATE.getFullYear() +
        1;
      const currentChapterNumber = currentDate.getMonth() + 1;

      setHero({
        ...hero,
        characterName: "",
        title: "",
        hasImages: true,
        bookNumber: currentBookNumber,
        chapterNumber: currentChapterNumber,
      });
    }
  }, [id]);

  const validate = () => {
    const errors = [];

    // Check for character name
    if (!hero.characterName) {
      errors.push("Missing character name");
    }

    // Check for title
    if (!hero.title) {
      errors.push("Missing title");
    }

    // Check for hero name
    if (!hero.heroName) {
      errors.push("Missing hero name");
    }

    // Check for short ID
    if (!hero.shortId) {
      errors.push("Missing short ID");
    }

    // Check for color
    if (!hero.heroColor?.id) {
      errors.push("Missing hero color");
    }

    // Check for weapon
    if (!hero.heroWeapon?.id) {
      errors.push("Missing hero weapon");
    }

    // Check for move type
    if (!hero.heroMoveType?.id) {
      errors.push("Missing hero move type");
    }

    // Check for ability
    if (!hero.heroAbility?.id) {
      errors.push("Missing hero ability");
    }

    // Check for source
    if (!hero.heroSource?.id) {
      errors.push("Missing hero source");
    }

    // Check for hero summoning pool
    if (!hero.heroSummoningPool?.id) {
      errors.push("Missing hero summoning pool");
    }

    // Check for games
    if (!hero.heroGames.length) {
      errors.push("Missing hero games");
    }

    // Check for external site id
    if (!hero.externalSiteId) {
      errors.push("Missing external site ID");
    }

    // Check for backup character name if needed
    if (hasBackupUnit && !hero.backupCharacterName) {
      errors.push("Missing backup character name");
    }

    return errors;
  };

  const saveHeroData = async () => {
    setFeedback(null);

    const errors = validate();
    if (errors.length) {
      setFeedback({
        type: "Negative",
        message: errors.join(", "),
      });
      return;
    }

    setLoading(true);

    const data: ApiHero = {
      shortId: hero.shortId,
      characterName: hero.characterName,
      heroName: hero.heroName,
      title: hero.title,
      colorId: hero.heroColor.id,
      weaponId: hero.heroWeapon.id,
      moveTypeId: hero.heroMoveType.id,
      abilityId: hero.heroAbility.id,
      sourceId: hero.heroSource.id,
      summoningPoolId: hero.heroSummoningPool.id,
      themeId: hero.heroTheme?.id ?? null,
      bannerId: null,
      firstAvailableDate: !!hero.firstAvailable
        ? isOnBanner || willBeOnBanner
          ? null
          : getDate(hero.firstAvailable)
        : null,
      bookNumber: hero.bookNumber,
      chapterNumber: hero.chapterNumber,
      leadGameId: hero.heroGames.length > 0 ? hero.heroGames[0].gameId : 0,
      backupGameId: hero.heroGames.length > 1 ? hero.heroGames[1].gameId : 0,
      hasImages: hero.hasImages,
      backupCharacterName: hero.backupCharacterName,
      externalSiteId: hero.externalSiteId,
    };

    // Add Engage as game for emblem heroes
    if (
      isEmblem &&
      data.backupGameId === 0 &&
      data.leadGameId !== Games.Engage
    ) {
      data.backupGameId = Games.Engage;
    }

    const options = {
      method: isNew ? "POST" : "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        password: adminPassword,
      },
      body: JSON.stringify(data),
    };

    const url = `${process.env.REACT_APP_API_URL}/Hero/${id}`;

    const response = await fetch(url, options);
    setLoading(false);

    if (response.status < 200 || response.status > 299) {
      setFeedback({
        type: "Negative",
        message: `${isNew ? "Create" : "Update"} failed (Status code: ${
          response.status
        })`,
      });
      return;
    }

    isNew = false;
    id = hero.shortId;

    setFeedback({
      type: "Positive",
      message: `${isNew ? "Create" : "Update"} successful (Status code: ${
        response.status
      })`,
    });
  };

  const setGeneratedShortId = () => {
    if (!!!hero.characterName) return;

    const newShortId = generateShortId();

    setHero({ ...hero, shortId: newShortId });
  };

  const generateShortId = () => {
    if (!!!hero.characterName) return "";

    let newShortId = hero.characterName
      .trim()
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(":", "")
      .replaceAll("-", "")
      .replaceAll("&", "and")
      .replaceAll("  ", " ")
      .replaceAll(" ", "-");

    const focusAbilities: number[] = [
      HeroAbilityIds.Legendary,
      HeroAbilityIds.Mythic,
      HeroAbilityIds.Ascended,
      HeroAbilityIds.Rearmed,
      HeroAbilityIds.Attuned,
      HeroAbilityIds.Emblem,
    ];

    if (focusAbilities.includes(hero.heroAbility.id)) {
      newShortId += `-${
        siteData.heroAbilities.find((x) => x.id === hero.heroAbility.id)?.name
      }`;
    } else if ((hero.heroTheme?.id ?? -1) > 1) {
      newShortId += `-${
        siteData.heroThemes.find((x) => x.id === hero.heroTheme?.id)?.name
      }`;
    } else {
      newShortId += "-base";
    }

    newShortId = newShortId.toLowerCase();

    return newShortId;
  };

  const scrapeImages = async () => {
    return await sendScrapeRequest("Hero");
  };

  const sendScrapeRequest = async (endpointName: "Hero") => {
    setFeedback(null);
    setLoading(true);

    const data: ScrapeRequest = {
      shortId: hero.shortId,
      externalId: hero.externalSiteId,
      scrapeData: false,
      scrapeImages: true,
    };

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        password: adminPassword,
      },
      body: JSON.stringify(data),
    };

    const url = `${process.env.REACT_APP_API_URL}/Scrape/${endpointName}`;

    const response = await fetch(url, options);
    setLoading(false);

    if (response.status < 200 || response.status > 299) {
      setFeedback({
        type: "Negative",
        message: `Scrape failed (Status code: ${response.status})`,
      });
      return;
    }

    setFeedback({
      type: "Positive",
      message: `Scrape successful (Status code: ${response.status})`,
    });
  };

  const isHarmonic = hero.heroAbility.id === HeroAbilityIds.Harmonized;
  const isEmblem = hero.heroAbility.id === HeroAbilityIds.Emblem;
  const hasBackupUnit =
    hero.heroAbility.id === HeroAbilityIds.Duo ||
    hero.heroAbility.id === HeroAbilityIds.Harmonized;
  const isOnBanner = !!hero.banner;
  const willBeOnBanner =
    !isOnBanner && hero.heroSource?.id === HeroSourceIds.Summoning;

  let summoningPoolMode: "dropdown" | "grailLocked" | "naLocked" = "dropdown";
  if (
    hero.heroSource?.id === HeroSourceIds.GrandHeroBattle ||
    hero.heroSource?.id === HeroSourceIds.TempestTrial
  ) {
    summoningPoolMode = "grailLocked";
  } else if (hero.heroSource?.id === HeroSourceIds.Story) {
    summoningPoolMode = "naLocked";
  }

  const leadUnitGameId =
    hero.heroGames && hero.heroGames.length > 0 ? hero.heroGames[0].gameId : 0;
  const backupUnitGameId =
    hero.heroGames && hero.heroGames.length > 1 ? hero.heroGames[1].gameId : 0;
  const firstAvailableDate = !!hero.firstAvailable
    ? (getDate(hero.firstAvailable) as Date)
    : Globals.FEH_APP_LAUNCH_DATE;

  const heroDisplayName = `${hero.characterName || "Hero"}: ${
    hero.title || "New Recruit"
  }`;

  return (
    <>
      <PageTitle>{heroDisplayName}</PageTitle>

      <div>
        <button onClick={setGeneratedShortId} disabled={!!hero.shortId}>
          Generate Short ID
        </button>
        <button onClick={saveHeroData}>Save</button>
      </div>
      <hr />
      {!!feedback && (
        <div>
          <Feedback feedback={feedback} />
        </div>
      )}
      <div className="hero-bio">
        <div className="hero-bio__image-panel">
          {!!hero.images.full.neutral ? (
            <HeroImagePane
              heroName={heroDisplayName}
              images={hero.images.full}
            />
          ) : (
            <span>Hero images not yet available</span>
          )}
        </div>
        <div className="hero-bio__info-panel">
          <h3>Hero data</h3>
          <TextDataField
            label="Character Name"
            value={hero.characterName}
            onChange={(value) => {
              setHero({
                ...hero,
                characterName: value,
              });
            }}
          />
          <TextDataField
            label="Title"
            value={hero.title}
            onChange={(value) => {
              setHero({
                ...hero,
                title: value,
              });
            }}
          />
          <TextDataField
            label="Hero Name"
            value={hero.heroName}
            onChange={(value) => {
              setHero({
                ...hero,
                heroName: value,
              });
            }}
          />
          <TextDataField
            label="Short ID"
            value={hero.shortId}
            placeholder={generateShortId()}
            onChange={(value) => {
              setHero({
                ...hero,
                shortId: value,
              });
            }}
          />
          <DataField label="Colour">
            <select
              value={hero.heroColor.id}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroColors.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };
                setHero({ ...hero, heroColor: findResult });
              }}
            >
              <option key="color-none" value=""></option>
              {siteData.heroColors &&
                siteData.heroColors.map((item) => {
                  return (
                    <option key={`color-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          <DataField label="Weapon">
            <select
              value={hero.heroWeapon.id}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroWeapons.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };
                setHero({ ...hero, heroWeapon: findResult });
              }}
            >
              <option key="weapon-none" value=""></option>
              {siteData.heroWeapons &&
                siteData.heroWeapons.map((item) => {
                  if (!!hero.heroColor.id) {
                    if (
                      hero.heroColor.id !== HeroColorIds.Red &&
                      item.id === HeroWeaponIds.Sword
                    )
                      return <></>;
                    if (
                      hero.heroColor.id !== HeroColorIds.Blue &&
                      item.id === HeroWeaponIds.Lance
                    )
                      return <></>;
                    if (
                      hero.heroColor.id !== HeroColorIds.Green &&
                      item.id === HeroWeaponIds.Axe
                    )
                      return <></>;
                    if (
                      hero.heroColor.id !== HeroColorIds.Colorless &&
                      item.id === HeroWeaponIds.Staff
                    )
                      return <></>;
                  }

                  return (
                    <option key={`weapon-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          <DataField label="Move Type">
            <select
              value={hero.heroMoveType.id}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroMoveTypes.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };
                setHero({ ...hero, heroMoveType: findResult });
              }}
            >
              <option key="movetype-none" value=""></option>
              {siteData.heroMoveTypes &&
                siteData.heroMoveTypes.map((item) => {
                  return (
                    <option key={`movetype-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          <DataField label="Ability">
            <select
              value={hero.heroAbility.id ?? ""}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroAbilities.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };

                // If one of the fixed abilities is selected, default other fields to match
                let newHeroSource = hero.heroSource;
                if (findResult.id !== HeroAbilityIds.Standard) {
                  newHeroSource =
                    siteData.heroSources.find(
                      (x) => x.id === HeroSourceIds.Summoning,
                    ) ?? newHeroSource;
                }

                let newHeroSummoningPool = hero.heroSummoningPool;
                if (
                  findResult.id === HeroAbilityIds.Legendary ||
                  findResult.id === HeroAbilityIds.Mythic ||
                  findResult.id === HeroAbilityIds.Emblem
                ) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) =>
                        x.id === HeroSummoningPoolIds.LegendaryMythicEmblem,
                    ) ?? newHeroSummoningPool;
                } else if (
                  findResult.id === HeroAbilityIds.Duo ||
                  findResult.id === HeroAbilityIds.Harmonized
                ) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) => x.id === HeroSummoningPoolIds.FiveStarSeasonal,
                    ) ?? newHeroSummoningPool;
                } else if (findResult.id === HeroAbilityIds.Ascended) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) => x.id === HeroSummoningPoolIds.FiveStarGeneral,
                    ) ?? newHeroSummoningPool;
                } else if (findResult.id === HeroAbilityIds.Rearmed) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) => x.id === HeroSummoningPoolIds.Rearmed,
                    ) ?? newHeroSummoningPool;
                } else if (findResult.id === HeroAbilityIds.Attuned) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) => x.id === HeroSummoningPoolIds.Attuned,
                    ) ?? newHeroSummoningPool;
                }

                setHero({
                  ...hero,
                  heroAbility: findResult,
                  heroSource: newHeroSource,
                  heroSummoningPool: newHeroSummoningPool,
                });
              }}
            >
              <option key="ability-none" value=""></option>
              {siteData.heroAbilities &&
                siteData.heroAbilities.map((item) => {
                  return (
                    <option key={`ability-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          <DataField label="Source">
            <select
              value={hero.heroSource.id}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroSources.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };

                // If Grail source is selected, ensure Grail summoning pool is set
                let newHeroSummoningPool = hero.heroSummoningPool;
                if (
                  findResult?.id === HeroSourceIds.GrandHeroBattle ||
                  findResult?.id === HeroSourceIds.TempestTrial
                ) {
                  newHeroSummoningPool =
                    siteData.heroSummoningPools.find(
                      (x) => x.id === HeroSummoningPoolIds.Grail,
                    ) ?? hero.heroSummoningPool;
                }

                setHero({
                  ...hero,
                  heroSource: findResult,
                  heroSummoningPool: newHeroSummoningPool,
                });
              }}
            >
              <option key="source-none" value=""></option>
              {siteData.heroSources &&
                siteData.heroSources.map((item) => {
                  return (
                    <option key={`source-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          <DataField label="Summoning Pool">
            {summoningPoolMode === "dropdown" && (
              <select
                value={hero.heroSummoningPool.id}
                onChange={(event: ChangeEvent) => {
                  let idString = ((event.target as any).value ?? "") as string;
                  let findResult = siteData.heroSummoningPools.find(
                    (x) => x.id.toString() === idString,
                  ) ?? { id: 0, name: "" };
                  setHero({ ...hero, heroSummoningPool: findResult });
                }}
              >
                <option key="summon-none" value=""></option>
                {siteData.heroSummoningPools &&
                  siteData.heroSummoningPools.map((item) => {
                    return (
                      <option key={`summon-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            )}
            {summoningPoolMode === "grailLocked" && <span>Grail</span>}
            {summoningPoolMode === "naLocked" && <span>N/A</span>}
          </DataField>
          <DataField label="Theme">
            <select
              value={hero.heroTheme?.id ?? ""}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.heroThemes.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, name: "" };
                setHero({ ...hero, heroTheme: findResult });
              }}
            >
              <option key="theme-none" value=""></option>
              {siteData.heroThemes &&
                siteData.heroThemes.map((item) => {
                  return (
                    <option key={`theme-${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </DataField>
          {!isOnBanner && !willBeOnBanner && (
            <DatepickerDataField
              label="First available"
              value={firstAvailableDate}
              onChange={(date) => {
                if (!!date) {
                  date.setHours(7);
                }
                setHero({ ...hero, firstAvailable: date });
              }}
            />
          )}
          {isOnBanner && (
            <DataTerm
              label="First available"
              value={firstAvailableDate.toISOString().split("T")[0]}
            />
          )}
          {willBeOnBanner && (
            <DataTerm label="First available" value="Determined by banner" />
          )}
          <DataField label="Book number">
            <input
              type="number"
              value={hero.bookNumber ?? 0}
              onChange={(event: ChangeEvent) => {
                setHero({
                  ...hero,
                  bookNumber: (event.target as any).value ?? "",
                });
              }}
            />
          </DataField>
          <DataField label="Chapter number">
            <input
              type="number"
              value={hero.chapterNumber ?? 0}
              onChange={(event: ChangeEvent) => {
                setHero({
                  ...hero,
                  chapterNumber: (event.target as any).value ?? "",
                });
              }}
            />
          </DataField>
          <DataField
            label={
              isHarmonic
                ? "Lead unit game"
                : isEmblem
                ? "Original game"
                : "Game"
            }
          >
            <select
              value={leadUnitGameId}
              onChange={(event: ChangeEvent) => {
                let idString = ((event.target as any).value ?? "") as string;
                let findResult = siteData.games.find(
                  (x) => x.id.toString() === idString,
                ) ?? { id: 0, title: "" };
                let newLink: HeroGameLink = { gameId: findResult.id };

                let newGames = hero.heroGames;
                if (hero.heroGames.length === 0) {
                  newGames.push(newLink);
                } else {
                  newGames[0] = newLink;
                }

                setHero({ ...hero, heroGames: newGames });
              }}
            >
              <option key="firstGame-none" value=""></option>
              {siteData.games &&
                siteData.games.map((item) => {
                  return (
                    <option key={`firstGame-${item.id}`} value={item.id}>
                      {item.title}
                    </option>
                  );
                })}
            </select>
          </DataField>
          {isHarmonic && (
            <DataField label="Backup unit game">
              <select
                value={backupUnitGameId}
                onChange={(event: ChangeEvent) => {
                  let idString = ((event.target as any).value ?? "") as string;
                  let findResult = siteData.games.find(
                    (x) => x.id.toString() === idString,
                  ) ?? { id: 0, title: "" };
                  let newLink: HeroGameLink = { gameId: findResult.id };

                  let newGames = hero.heroGames;
                  if (newGames.length === 0) {
                    newGames.push({ gameId: 0 });
                    newGames.push(newLink);
                  } else if (newGames.length === 1) {
                    newGames.push(newLink);
                  } else {
                    newGames[1] = newLink;
                  }

                  setHero({ ...hero, heroGames: newGames });
                }}
              >
                <option key="firstGame-none" value=""></option>
                {siteData.games &&
                  siteData.games.map((item) => {
                    return (
                      <option key={`firstGame-${item.id}`} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
              </select>
            </DataField>
          )}
          {hasBackupUnit && (
            <TextDataField
              label="Backup character name"
              value={hero.backupCharacterName}
              onChange={(value) => {
                setHero({
                  ...hero,
                  backupCharacterName: value,
                });
              }}
            />
          )}
          <DataField label="Has images?">
            <input
              type="checkbox"
              checked={hero.hasImages}
              onChange={(event: ChangeEvent) => {
                let isChecked = (event.target as HTMLInputElement).checked;
                setHero({ ...hero, hasImages: isChecked });
              }}
            />
          </DataField>

          <hr />

          <h3>Scraping</h3>
          <TextDataField
            label="External site ID"
            value={hero.externalSiteId}
            onChange={(value) => {
              setHero({
                ...hero,
                externalSiteId: parseInt(value),
              });
            }}
          />
          <button onClick={scrapeImages}>Scrape images</button>
        </div>
      </div>
    </>
  );
}

export default ManageHero;
