import { useState } from "react";
import HeroFilterPanel from "../components/HeroFilterPanel/HeroFilterPanel";
import HeroGroup from "../components/HeroGroup/HeroGroup";
import GroupingField from "../lookups/GroupingField";
import HeroGroupPanel from "../components/HeroGroupPanel/HeroGroupPanel";
import HeroSortPanel from "../components/HeroSortPanel/HeroSortPanel";
import HeroSortField from "../lookups/HeroSortField";
import GroupSortField from "../lookups/GroupSortField";
import {
  buildHeroFilter,
  groupHeroes,
  sortGroupsAndHeroes,
} from "../helpers/heroHelper";
import { useCollection, useFilters, useSiteData } from "../reducer/hooks";
import PageTitle from "../components/PageTitle";

function Collection() {
  const { heroes, games } = useSiteData();
  const filters = useFilters();
  const collecion = useCollection();

  const [groupingField, setGroupingField] = useState<GroupingField>("None");
  const [heroSortField, setHeroSortField] =
    useState<HeroSortField>("Release Date");
  const [groupSortField, setGroupSortField] = useState<GroupSortField>("Count");

  const books = Array.from(
    new Set(heroes.map((x) => x.bookNumber).filter((x) => !!x)).values(),
  ).sort() as number[];

  // Step 1: Filter
  const filterHeroes = buildHeroFilter(filters, collecion);
  const filteredHeroes = heroes.filter(filterHeroes);

  // Step 2: Group
  let groups = groupHeroes(filteredHeroes, groupingField, {
    games: games,
  });

  // Step 3: Sort
  groups = sortGroupsAndHeroes(groups, heroSortField, groupSortField);

  return (
    <>
      <PageTitle>My Collection</PageTitle>

      <h3>Filters</h3>
      <HeroFilterPanel bookOptions={books} />
      <h3>Grouping</h3>
      <HeroGroupPanel
        groupingField={groupingField}
        setGroupingField={setGroupingField}
      />
      <h3>Sorting</h3>
      <HeroSortPanel
        heroSortField={heroSortField}
        setHeroSortField={setHeroSortField}
        groupSortField={groupSortField}
        setGroupSortField={setGroupSortField}
        groupingFieldHint={groupingField}
      />
      <hr />
      {groups.map((group) => {
        return <HeroGroup key={`collectiongroup-${group.id}`} group={group} />;
      })}
    </>
  );
}

export default Collection;
