import { NavLink } from "react-router-dom";
import { formatDateAsString } from "../../helpers/dateHelper";
import Tick from "../Tick";
import WishlistButton from "../WishlistButton";
import HeroInfo from "../../types/HeroInfo";
import { isInCollection, isInWishlist } from "../../helpers/collectionHelper";
import { useCollection, useDispatch, useSettings } from "../../reducer/hooks";
import HeroSourceIds from "../../lookups/HeroSourceIds";

type HeroTableRowProps = {
  hero: HeroInfo;
};

const HeroTableRow = ({ hero }: HeroTableRowProps) => {
  const { obtainedStyle } = useSettings();
  const { collection, wishlist } = useCollection();
  const dispatch = useDispatch();

  const checkboxChanged = (shortId: string) => {
    const isIn = isInCollection(shortId, collection);

    dispatch({
      type: !isIn
        ? "ADD_HEROES_TO_COLLECTION"
        : "REMOVE_HEROES_FROM_COLLECTION",
      shortIds: [shortId],
    });
  };

  const wishlistChanged = (shortId: string) => {
    const isIn = isInWishlist(shortId, wishlist);

    dispatch({
      type: !isIn ? "ADD_HEROES_TO_WISHLIST" : "REMOVE_HEROES_FROM_WISHLIST",
      shortIds: [shortId],
    });
  };

  const obtained = isInCollection(hero.shortId, collection);
  const wishlisted = isInWishlist(hero.shortId, wishlist);

  if (obtained && obtainedStyle === "hide") {
    return <></>;
  }

  const weaponImageUrl = `/icons/weapons/${hero.heroColor.name}${hero.heroWeapon.name}.png`;
  const moveTypeImageUrl = `/icons/movetypes/${hero.heroMoveType.name}.png`;

  return (
    <tr key={hero.shortId}>
      <td>
        <NavLink to={`/Hero/${hero.shortId}`}>
          {hero.characterName}: {hero.title}
        </NavLink>
        <div className="smallAndDown">
          <br />
          <img
            className="weapon-icon"
            src={weaponImageUrl}
            alt={hero.heroWeapon.name}
            loading="lazy"
          />
          <span>&#9;</span>
          <img
            className="move-type-icon"
            src={moveTypeImageUrl}
            alt={hero.heroMoveType.name}
            loading="lazy"
          />
        </div>
      </td>
      <td className="mediumAndUp--table-cell">
        <img
          className="weapon-icon"
          src={weaponImageUrl}
          alt={hero.heroWeapon.name}
          loading="lazy"
        />
      </td>
      <td className="mediumAndUp--table-cell">
        <img
          className="move-type-icon"
          src={moveTypeImageUrl}
          alt={hero.heroMoveType.name}
          loading="lazy"
        />
      </td>
      <td className="mediumAndUp--table-cell">
        {formatDateAsString(hero.firstAvailable, "fullDate")}
      </td>
      <td className="smallAndUp--table-cell">
        {hero.heroSource.name}{" "}
        {hero.heroSource.id === HeroSourceIds.Summoning
          ? ` - ${hero.heroSummoningPool.name}`
          : ""}
      </td>
      <td>
        <Tick
          checked={obtained}
          onChange={() => {
            checkboxChanged(hero.shortId);
          }}
        />
      </td>
      <td>
        {!obtained && (
          <WishlistButton
            active={wishlisted}
            onChange={() => {
              wishlistChanged(hero.shortId);
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default HeroTableRow;
