import "./WishlistButton.scss";

type WishlistButtonProps = {
  active: boolean;
  onChange: () => void;
};

function WishlistButton(props: WishlistButtonProps) {
  const uiImageRoot = `${process.env.REACT_APP_STORAGE_URL}ui`;

  let classes = "wishlist-button";
  if (!props.active) {
    classes += " wishlist-button--inactive";
  }

  const action = props.onChange ?? (() => {});

  return (
    <img
      className={classes}
      src={`${uiImageRoot}/heart.png`}
      alt="Toggle wishlist state"
      onClick={action}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          e.preventDefault();
          action();
        }
      }}
      loading="lazy"
      tabIndex={0}
    />
  );
}

export default WishlistButton;
