import "./Tick.scss";

type TickProps = {
  checked: boolean;
  onChange?: () => void;
};

function Tick(props: TickProps) {
  const uiImageRoot = `${process.env.REACT_APP_STORAGE_URL}ui`;

  let src = `${uiImageRoot}/tick-grey.png`;
  if (props.checked) {
    src = `${uiImageRoot}/tick-green.png`;
  }

  const action = props.onChange ?? (() => {});

  return (
    <img
      className="tick"
      src={src}
      alt="Toggle hero"
      onClick={action}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          e.preventDefault();
          action();
        }
      }}
      loading="lazy"
      tabIndex={0}
    />
  );
}

export default Tick;
