import FeatGroupType from "../../types/FeatGroup";
import HeroInfo from "../../types/HeroInfo";
import FeatTile from "../FeatTile/FeatTile";

type FeatGroupProps = {
  featGroup: FeatGroupType;
  heroData: HeroInfo[];
};

function FeatGroup(props: FeatGroupProps) {
  return (
    <div>
      <hr />
      <h3>{props.featGroup.name}</h3>
      <p>{props.featGroup.description}</p>
      {props.featGroup.feats.map((feat) => (
        <FeatTile
          key={`feattile-${feat.shortId}`}
          feat={feat}
          heroData={props.heroData}
        />
      ))}
    </div>
  );
}

export default FeatGroup;
