import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Error } from "./pages";
import NavBar from "./components/NavBar";
import LoadingCover from "./components/LoadingCover";
import { fetchSiteData } from "./helpers/siteDataHelper";
import { AppStateContext, useAppReducer } from "./reducer";
import Globals from "./lookups/Globals";
import { isInLocalStorage } from "./helpers/localStorageHelper";
import { adminPasswordKey } from "./lookups/LocalStorage";

import "./App.scss";
import Routing from "./components/Routing";
import { ErrorBoundary } from "react-error-boundary";

const App = () => {
  const [state, dispatch] = useAppReducer();

  // TODO: Better way to pull these out of state?
  const { loading, broken } = state.navigation;

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      loading: loading,
    });
  };

  const setBroken = (broken: boolean) => {
    dispatch({
      type: "SET_BROKEN",
      broken: broken,
    });
  };

  const refreshSiteData = () => {
    setLoading(true);
    fetchSiteData()
      .then((data) => {
        // Finish loading
        setLoading(false);

        // Set site data
        dispatch({
          type: "SET_SITE_DATA",
          data: data,
        });
      })
      .catch((err) => {
        console.error(err);
        setBroken(true);
      });
  };

  // Initial page load tasks
  useEffect(() => {
    // Fetch site data from server
    refreshSiteData();

    // Load collection
    dispatch({
      type: "LOAD_COLLECTION",
    });

    // Load site settings
    dispatch({
      type: "LOAD_SETTINGS",
    });

    if (isInLocalStorage(adminPasswordKey)) {
      dispatch({
        type: "LOAD_ADMIN_PASSWORD",
      });
      dispatch({
        type: "SET_MANAGE_AREAS_STATE",
        show: true,
      });
    }
  }, []);

  const inManageArea = window.location.href.includes("/Manage");

  return (
    <div className="App">
      <AppStateContext.Provider value={[state, dispatch]}>
        <Router>
          <header>
            <div className="width-bounder">
              <div className="heading-flex-box">
                <h1>{Globals.WEBSITE_NAME}</h1>
                {!loading && inManageArea && (
                  <button onClick={refreshSiteData}>Refresh data</button>
                )}
              </div>
              {!broken && <NavBar />}
            </div>
          </header>
          <main>
            {!broken ? (
              <>
                <div className="width-bounder">
                  <ErrorBoundary
                    onError={() => setBroken(true)}
                    fallback={<Error />}
                  >
                    <Routing />
                  </ErrorBoundary>
                </div>
                <LoadingCover active={loading} />
              </>
            ) : (
              <>
                <Error />
              </>
            )}
          </main>
          <footer>
            <div className="width-bounder"></div>
          </footer>
        </Router>
      </AppStateContext.Provider>
    </div>
  );
};

export default App;
