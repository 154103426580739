import FeatGroup from "../components/FeatGroup/FeatGroup";
import PageTitle from "../components/PageTitle";
import { useSiteData } from "../reducer/hooks";
import FeatGroupType from "../types/FeatGroup";

function Feats() {
  const { heroes, feats } = useSiteData();

  let featGroups: FeatGroupType[] = [];
  for (let feat of feats) {
    let id = feat.featCategory.id.toString();
    let group = featGroups.find((x) => x.id === id);
    if (!group) {
      group = {
        id,
        name: feat.featCategory.name,
        description: feat.featCategory.description,
        feats: [],
      };
      featGroups.push(group);
    }

    group.feats.push(feat);
  }

  // Cheat to bubble Overall category to top, instead of exposing order field from API
  featGroups = featGroups.sort((a, b) => {
    if (a.id === "5") return -1;
    if (b.id === "5") return 1;

    return 0;
  });

  return (
    <>
      <PageTitle>Feats</PageTitle>

      {featGroups.map((group) => {
        return (
          <FeatGroup
            key={`featgroup-${group.id}`}
            featGroup={group}
            heroData={heroes}
          />
        );
      })}
    </>
  );
}

export default Feats;
